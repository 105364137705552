import { AppRouteProps } from "@mzara/component";
import React from "react";

const StaticPageCreateContainer = React.lazy(() => import("./containers/StaticPageCreateContainer"))

export const StaticPageCreateRoutes: Array<AppRouteProps> = [
    {
        path: '-1',
        element: <StaticPageCreateContainer/>
    }
]