import { AppRouteProps } from "@mzara/component";
import React from "react";

const CreateDocumentContainer = React.lazy(
    () => import("./container/CreateDocumentContainer")
);
export const createDocumentRoutes: Array<AppRouteProps> = [
    {
        title: "Harea.Document.Template.Create",
        element: <CreateDocumentContainer />,
        path: "create",
    },
];
