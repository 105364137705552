import { AppRouteProps } from "@mzara/component";
import React from "react";

const StaticPageDetailsContainer = React.lazy(() => import("./containers/StaticPageDetailsContainer"))
const StaticPageDetailsAbout = React.lazy(() => import('./views/about/containers/StaticPageDetailsAbout'))
const StaticPageDetailsDesigner = React.lazy(() => import('./views/designer/containers/StaticPageDetailsDesigner'))
const StaticPageDetailsPreview = React.lazy(() => import('./views/preview/containers/StaticPageDetailsPreview'))
const StaticPageDetailsParams = React.lazy(() => import('./views/params/containers/StaticPageDetailsParams'))

export const StaticPageDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{ArticleDetailsTitle}}",
        element: <StaticPageDetailsContainer/>,
        children: [
            {
                path: 'about',
                title: 'std_about',
                element: <StaticPageDetailsAbout/>
            },
            {
                path: 'designer',
                title: 'std_designer',
                element: <StaticPageDetailsDesigner/>
            },
            {
                path: 'preview',
                title: 'std_preview',
                element: <StaticPageDetailsPreview/>
            },
            {
                path: "params",
                title: "std_params",
                element: <StaticPageDetailsParams/>
            }
        ]
    }
] 