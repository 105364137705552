
import { GenericSuspense } from "@mzara/component";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {

    return (
        <div className="auth-layout">
            <GenericSuspense>
                <div className="flex justify-center pt-10">
                    <div className="max-w-[360px]">
                        <Outlet />
                    </div>
                </div>
            </GenericSuspense>
        </div>
    )
}

export default AuthLayout;
