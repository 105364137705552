import { AppRouteProps } from "@mzara/component";
import React from "react";
import { createDocumentRoutes } from "view/create/createDocumentRoutes";
import { deleteDocumentRoutes } from "view/delete/deleteDocumentRoutes";
import { editDocumentRoutes } from "view/edit/editDocumentRoutes";

const DocumentContainer = React.lazy(
    () => import("./container/DocumentContainer")
);
const DocumentDetailsContainer = React.lazy(
    () =>
        import(
            "./view/document-template-details/container/DocumentDetailsContainer"
        )
);
export const DocumentRoutes: Array<AppRouteProps> = [
    {
        path: "document-template",
        title: "Harea.Document.Template",
        element: <DocumentContainer />,
        children: [
            ...createDocumentRoutes,
            ...editDocumentRoutes,
            ...deleteDocumentRoutes,
        ],
        roles: ["DOCUMENT_TEMPLATE"]
    },
    {
        title: "Harea.Document.Template.Details",
        element: <DocumentDetailsContainer />,
        path: "document-template/:id/details",
        roles: ["DOCUMENT_TEMPLATE"]

    },
];
