import { AppRouteProps } from "@mzara/component";
import React from "react";

const EditDocumentContainer = React.lazy(
    () => import("./container/EditDocumentContainer")
);
export const editDocumentRoutes: Array<AppRouteProps> = [
    {
        title: "Harea.Document.Template.Edit",
        element: <EditDocumentContainer />,
        path: ":id/edit",
    },
];
