import React, { useEffect, useState } from 'react';
import { AppRoot, MainLogo, useCdnAssets } from '@mzara/component'
import { BoRoutes } from './page/BoRoutes'
import './App.css'

const App: React.FC = () => {

    const cdn = useCdnAssets()

    return (
        <AppRoot 
            routes={BoRoutes} 
            protectedRoutes="*"
            unProtectedRoutes={UNPROTECTED_ROUTES}
            loginPath={process.env.REACT_APP_LOGIN_URL!}
            suspenseFallback={<MainLogo img={cdn('/img/isa/logo.svg')} />}
            />
    );
}

const UNPROTECTED_ROUTES = [
    'user/signup'
]

export default App;

