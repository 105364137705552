import { AppRouteProps } from "@mzara/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import { TranslationRoutes } from '@mzara/translation';
import { AuthRoutes } from '@mzara/login'
import { WizardRoutes } from '@mzara/wizard'
import { WizardNodeRoutes } from '@mzara/wizard-node'
import AuthLayout from "components/layout/AuthLayout";
import { Outlet } from "react-router-dom";
import { DocumentRoutes } from "@mzara/document-template"
import { StaticPageRoutes } from "@mzara/static-page"
import { UserDetailsRoutes } from '@mzara/user-management'

const MainLayout = React.lazy(() => import("components/layout/MainLayout"));

export const BoRoutes: Array<AppRouteProps> = [
    {
        path: ':lang',
        title: `Bo.title`,
        element: <Outlet />,
        children: [

            {
                path: '',
                element: <MainLayout />,
                children: [
                    ...HomeRoutes,
                    ...TranslationRoutes,
                    ...WizardRoutes,
                    ...WizardNodeRoutes,
                    ...DocumentRoutes,
                    ...StaticPageRoutes,
                    ...UserDetailsRoutes
                ]
            },
            {
                path: '',
                element: <AuthLayout />,
                children: [
                    ...AuthRoutes,
                ]
            },
        ]
    }
]