import { AppRouteProps } from "@mzara/component";
import React from "react";

const DeleteDocumentContainer = React.lazy(
    () => import("./container/DeleteDocumentContainer")
);
export const deleteDocumentRoutes: Array<AppRouteProps> = [
    {
        title: "Harea.Document.Template.Delete",
        path: ":id/delete",
        element: <DeleteDocumentContainer />,
    },
];
